@import "aside-fiche";
@import "entete-fiche";
@import "diapo-fiche";
@import "../../lib/light-gallery";
@import "informations-fiche-vehicule";
@import "calcul-carte-grise";
@import "tunnel-devis";

#setupDevis{
  position: fixed;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: $grisFond;
  z-index: 1000;
  //height: calc(100% - 93px);
  overflow: auto;
  transition: 0.5s ease-in-out;
  .contentDevis{
    overflow: auto;
    @include shadowBox(0.3);
    background-color: $blanc;
    .whiteCard{
      margin-bottom: 2em;
      padding-bottom: 10px;
    }
  }

  .containTunnel{
    margin: 0;
  }

  .footerDevis{
    @include shadowBox(0.3);
    background-color: $blanc;
    position: relative;

  }

  .containButtonBottomTunnel{
    padding-top: 1.5em;
    @media only screen {
      @media(min-width: $screen-sm-min) {
        padding-bottom: 1.5em;
      }
      @media(max-width: $screen-xs-max) {
        div > :last-child, div > :first-child {
          text-align: center;
          * {
            width: 100%;
          }
        }
      }
    }
  }
}

.containerExpedicarImg{
  display: inline;
  margin-left: 10px;
  padding-top: 3px;
  padding-bottom: 5px;
  padding-left: 12px;
  padding-right: 10px;
  border-radius: 15px;
}

.expedicarImg{
  margin-left: 5px;
  width: 24px;
}

.priceJourney{
  padding-left: 1px;
}


.gear{
  margin-left: 10px;
}

.optionsList {
  position: initial;
}

.promotionBodyBckgrd {
  background-size: cover;
  //background-image: url('../../../../resources/front/images/fiche-vehicule/promotions/promo-noel.gif');
  background-image: url('../../../../resources/front/images/fiche-vehicule/promotions/promo-bf.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 0 56px;
}

.promoBckgrd {
  background-color: $grisFond;
}

.excluProSize{
  font-weight: bold;
  font-size: 1.2em;
}
#deliveryFormQuotation, #containerDeliveryModeExpedicarQuotation{
    @media (max-width: $screen-xs-max){
        label{
            font-size: 14px;
        }
    }
}
#containerDeliveryModeExpedicarQuotation{
    @media (max-width: $screen-xs-max){
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
    label{
        margin-left: 5px;
        width: 80%;
    }
}

body, #modal-document0{
  padding-right: 0 !important;
  @media (max-width: $screen-sm-max){
    .container-btn{
    text-align: center;
    margin: 10px 0;
    }
   }
}

#withReprise{
  overflow: visible !important;
  z-index: 1051;
  padding-right: 0 !important;
  @media (max-width: $screen-sm-max){
    padding-right: 0 !important;
  }

  .input-group{
    padding: 0 20px;
    @media (max-width: $screen-sm-max){
      margin-bottom: 10px;
      display: block;
    }
    label{
      font-size: 15px;
      @media (max-width: $screen-xs-max){
        font-size: 14px;
      }
    }
    .jcf-select {
      width: 100%;
    }
    input[type='date']{
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2), inset 0 0 10px rgba(0, 0, 0, 0);
      border: 1px solid #cccccc;
      color: #22286d;
      font-weight: 600;
      font-size: 16px;
      outline: none;
    }
      .jcf-select-form-control{
          max-width: 255px;
          @media (max-width: $screen-sm-max) {
              max-width: 100%;
          }
      }
  }
  .confirm{
    padding-top: 45px;
    @media (max-width: $screen-sm-max){
      >div{
        float: none !important;
        margin: 0 auto;
      }
    }

  }
}

#similarCarsInStock, #similarCarsNoColorInStock {
  margin-top:0;
}

.similarCarsInStock{
  margin-bottom:0.1em;
}

.btn-similarCarinStock{
  background-color: $bleuEm;
  border-color: $bleuEm;
  margin: 1em;
}
