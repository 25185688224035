.containCoordonneesCommande{
  .whiteCard{
    label {
        &.withExpedicar{
            display: flex;
            margin: 1em 0;
            .textExpedicar{
                width: 80%;
            }
        }
        .jcf-radio{
            margin: 0 10px 0 0;
        }
        @media (max-width: $screen-xs-max){
            display: flex;

            .content{
                width: 80%;
            }
        }
      p {
        display: inline-flex;
        vertical-align: middle;
        margin-bottom: 0;
      }
    }
    .containerMap{
        @media (max-width: $screen-xs-max) {
          text-align: center;
          margin-bottom: 1em;
        }
    }
  }
    h6{
        margin-bottom: 1em;
        &.titleDeliveryPoint{
            margin-top: 1em;
        }
    }
}

.containerAccountAdresses {
  label {
    margin-bottom: 1em;

  }
}

.inputDelivery {
  display: inline-flex;
}

.inputVert {
  border: 1px solid #bed62f;
}

.inputRose {
  border: 1px solid #e0055a;
}

.expedicarImgOrder{
  margin-left: 5px;
  width: 20px;
}

.containerExpedicarImgOrder{
  display: inline-block;
  line-height: 1;
  padding-bottom: 3px;
  padding-left: 12px;
  padding-right: 10px;
  border-radius: 15px;
}
